import React from 'react';
import { PaymentOptions } from '@wix/ambassador-pricing-plans-read-api/types';
import { useTranslation, useEnvironment } from '@wix/yoshi-flow-editor';
import { NO_BREAK_SPACE, VIEWER_HOOKS } from '../../../../../constants';
import { getPeriodLabel } from '../../../../../utils';
import { st, classes } from './Recurrence.st.css';

interface Props {
  paymentOptions?: PaymentOptions;
  recurringPlansExist: boolean;
  highlighted: boolean;
}

export const Recurrence: React.FC<Props> = ({ paymentOptions, recurringPlansExist, highlighted }) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  if (!recurringPlansExist) {
    return null;
  }

  return (
    <span data-hook={VIEWER_HOOKS.PLAN_RECURRENCE} className={st(classes.frequency, { highlighted, mobile: isMobile })}>
      {paymentOptions?.recurring ? getPeriodLabel(paymentOptions?.validFor, t) : NO_BREAK_SPACE}
    </span>
  );
};
