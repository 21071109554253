import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings, useStyles } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { VIEWER_HOOKS } from '../../../../../constants';
import { useSettingsParams } from '../../../../../hooks';
import stylesParams from '../../../stylesParams';
import { classes } from './Ribbon.st.css';

interface Props {
  highlighted: boolean;
}

export const Ribbon: React.FC<Props> = ({ highlighted }) => {
  const { t } = useTranslation();
  const settingsParams = useSettingsParams();
  const settings = useSettings();
  const styles = useStyles();
  const showRibbon = styles.get(stylesParams.showRibbon);

  if (!highlighted || !showRibbon) {
    return null;
  }

  return (
    <div data-hook={VIEWER_HOOKS.PLAN_RIBBON} id="ribbon" className={classes.ribbon}>
      {settings.get(settingsParams.ribbonText) || t('pp.defaults.ribbon')}
    </div>
  );
};
