import { PeriodUnit, Validity } from '@wix/ambassador-pricing-plans-read-api/types';

export const getPeriodLabel = (validFor: Validity | undefined, t: any) => {
  const unit = validFor?.period?.unit;

  if (!unit) {
    return null;
  }

  switch (unit) {
    case PeriodUnit.WEEK:
      return t('pp.period-recurrency.every-week');
    case PeriodUnit.YEAR:
      return t('pp.period-recurrency.every-year');
    case PeriodUnit.MONTH:
      return t('pp.period-recurrency.every-month');
    default:
      return '';
  }
};
