import React from 'react';
import { PublicPlan } from '@wix/ambassador-pricing-plans-read-api/types';
import { useTranslation, useEnvironment } from '@wix/yoshi-flow-editor';
import { NO_BREAK_SPACE } from '../../../../../constants';
import { useAdaptedSettings, DynamicTaglineProvider } from '../../../../../hooks';
import { getPlanValidityCycle } from '../../../../../utils';
import { orderPlansForMobileView } from '../../../../../utils/orderPlansForMobileView';
import { Plan } from '../Plan';
import { classes } from './PlanList.st.css';

// Max item width + margins;
export const MAX_ITEM_WIDTH = 454 + 60;

export interface PlanListProps {
  plans: PublicPlan[];
  showDemoHighlight?: boolean;
  expandMobileBenefits: boolean;
  selectPlan(plan: PublicPlan): void;
}

export const PlanList: React.FC<PlanListProps> = ({ plans, showDemoHighlight, selectPlan, expandMobileBenefits }) => {
  const settings = useAdaptedSettings();
  const { t } = useTranslation();
  const { isMobile, isEditorX } = useEnvironment();

  const highlightedPlanId = settings.getHighlightedPlanId(plans, showDemoHighlight);
  let orderedPlans = settings.getOrderedPlans(plans);
  if (isMobile && highlightedPlanId) {
    orderedPlans = orderPlansForMobileView(orderedPlans, highlightedPlanId);
  }

  const recurringPlansExist = orderedPlans.filter((p) => p.paymentOptions?.recurring).length > 0;
  const freeTrialDaysExist = orderedPlans.filter((p) => p.paymentOptions?.freeTrialDays).length > 0;
  const taglinesExist = orderedPlans.filter((p) => p.details?.description).length > 0;
  const validityCyclesExist = orderedPlans.filter((p) => getPlanValidityCycle(p, t) !== NO_BREAK_SPACE).length > 0;
  const anyBenefits = !!orderedPlans.find((p) => p.details?.benefits?.length);

  const maxWidth = MAX_ITEM_WIDTH * orderedPlans.length;
  return (
    <DynamicTaglineProvider>
      <ul className={classes.container} style={isEditorX ? {} : { maxWidth }}>
        {orderedPlans.map((plan) => (
          <li key={plan.id} data-hook="plan">
            <Plan
              expandMobileBenefits={expandMobileBenefits}
              anyBenefits={anyBenefits}
              highlighted={highlightedPlanId === plan.id}
              plan={plan}
              recurringPlansExist={recurringPlansExist}
              freeTrialDaysExist={freeTrialDaysExist}
              validityCyclesExist={validityCyclesExist}
              taglinesExist={taglinesExist}
              onClick={() => selectPlan(plan)}
            />
          </li>
        ))}
      </ul>
    </DynamicTaglineProvider>
  );
};
