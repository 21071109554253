import React, { FC } from 'react';
import { WidgetProps } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { SettingsAdapterProvider, StylesParamsProvider } from '../../../hooks';
import { PlanListInteractions } from '../../../types/PlanListFedops';
import List, { ListProps } from '../../PackagePicker/Widget/List';
import { PlanListSettingsAdapter } from '../PlanListSettingsAdapter';
import settingsParams from '../settingsParams';
import stylesParams from '../stylesParams';

const Widget: FC<WidgetProps<ListProps>> = (props) => {
  const settings = useSettings();
  const settingsAdapter = React.useMemo(() => new PlanListSettingsAdapter(settings, settingsParams), [settings]);
  return (
    <StylesParamsProvider stylesParams={stylesParams}>
      <SettingsAdapterProvider adapter={settingsAdapter}>
        <List {...props} dataHook="PlanList-wrapper" interactionEnd={PlanListInteractions.WidgetLoaded} />
      </SettingsAdapterProvider>
    </StylesParamsProvider>
  );
};

export default Widget;
