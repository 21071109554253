import React from 'react';
import { PublicPlan } from '@wix/ambassador-pricing-plans-read-api/types';
import { useTranslation, useEnvironment } from '@wix/yoshi-flow-editor';
import { VIEWER_HOOKS } from '../../../../../constants';
import { getFreeTrialDaysLabel } from '../../../../../utils';
import { st, classes } from './FreeTrial.st.css';

interface Props {
  plan: PublicPlan;
  freeTrialDaysExist: boolean;
  highlighted: boolean;
}

export const FreeTrial: React.FC<Props> = ({ plan, freeTrialDaysExist, highlighted }) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  if (!freeTrialDaysExist) {
    return null;
  }

  return (
    <span data-hook={VIEWER_HOOKS.PLAN_FREE_TRIAL} className={st(classes.duration, { highlighted, mobile: isMobile })}>
      {getFreeTrialDaysLabel(plan, t)}
    </span>
  );
};
